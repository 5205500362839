"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
const PreviousRouteContext_1 = require("../context/PreviousRouteContext");
const useGoBack = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const previousUrl = (0, PreviousRouteContext_1.usePreviousRoute)();
    return () => {
        if (previousUrl && previousUrl !== location.pathname) {
            // Retourne à l'URL précédente si elle est différente de l'URL actuelle
            navigate(previousUrl);
        }
        else {
            // Redirige vers la page d'accueil si aucune URL précédente n'est valide
            navigate('/');
        }
    };
};
exports.default = useGoBack;
