"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class User {
    constructor({ id = null, email = '', phoneNumber = '', roles = [], }) {
        this._id = id;
        this._email = email;
        this._phoneNumber = phoneNumber;
        this._roles = roles;
        (0, mobx_1.makeAutoObservable)(this);
    }
    // Getters and Setters
    get id() {
        return this._id;
    }
    set id(value) {
        this._id = value;
    }
    get email() {
        return this._email;
    }
    set email(value) {
        this._email = value;
    }
    get phoneNumber() {
        return this._phoneNumber;
    }
    set phoneNumber(value) {
        this._phoneNumber = value;
    }
    get roles() {
        return this._roles;
    }
    set roles(value) {
        this._roles = value;
    }
    hasRole(role) {
        return this._roles.includes(role);
    }
}
exports.default = User;
