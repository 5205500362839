"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class OrderPizzas {
    constructor({ id = null, pizza, quantity = 0, }) {
        this.id = id;
        this.pizza = pizza;
        this.quantity = quantity;
        (0, mobx_1.makeAutoObservable)(this);
    }
    toJson() {
        return {
            pizzaId: this._pizza.id,
            quantity: this._quantity,
        };
    }
    get price() {
        return this.pizza.price * this.quantity;
    }
    get id() {
        return this._id;
    }
    set id(value) {
        this._id = value;
    }
    get pizza() {
        return this._pizza;
    }
    set pizza(value) {
        this._pizza = value;
    }
    get quantity() {
        return this._quantity;
    }
    set quantity(value) {
        if (value < 0) {
            value = 0;
        }
        this._quantity = value;
    }
}
exports.default = OrderPizzas;
