"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigate = exports.setNavigate = void 0;
// src/services/NavigationService.ts
let navigateFunction;
const setNavigate = (navigate) => {
    navigateFunction = navigate;
};
exports.setNavigate = setNavigate;
const navigate = (path, options) => {
    if (navigateFunction) {
        navigateFunction(path, options);
    }
    else {
        console.error('Navigate function is not set');
    }
};
exports.navigate = navigate;
