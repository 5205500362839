import { makeAutoObservable } from 'mobx';
import axios from '../httpClient';
import { navigate } from '../services/NavigationService';

class AxiosError {
  _errors;

  _loading = false;

  constructor() {
    makeAutoObservable(this);

    axios.interceptors.request.use(
      (config) => {
        this.errors = undefined;
        this.loading = true;
        return config;
      },
    );
    axios.interceptors.response.use(
      (response) => {
        this.errors = undefined;
        this.loading = false;
        return response;
      },
      (error) => {
        if (error?.response?.data) {
          this.errors = error?.response?.data;
        }
        this.loading = false;

        if (error.response) {
          const { status } = error.response;
          const requestUrl = error.config.url;
          // Redirection automatique si erreur 401
          if (status === 401 && requestUrl !== '/me') {
            navigate('/login', { replace: true });
          } else if (status === 403) {
          // Redirection vers la page d'accès interdit pour les erreurs 403
            navigate('/403', { replace: true });
          } else if (status === 404) {
          // Redirection vers la page non trouvée pour les erreurs 404
            navigate('/404', { replace: true });
          }
        }

        return Promise.reject(error);
      },
    );
  }

  get errors() {
    return this._errors;
  }

  set errors(value) {
    if (value) console.log(value);
    this._errors = value;
  }

  get loading() {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
  }

// addError(error) {
  //   console.log(error);
  //   this.errors.push(error);
  // }
  //
  // clearErrors() {
  //   this.errors = [];
  // }
}

export default AxiosError;
